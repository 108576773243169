/* wraps entire project with global css here */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: -moz-scrollbars-vertical;  */
}

/* fail */
/* .border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(25, 135, 84), var(--bs-border-opacity)) !important;
} */

/* custom fonts */
@font-face {
  font-family: helvetica-medium;
  src: url(../public/font/Helvetica/HVNP65-M.otf);
}
.helveticaHead {
  font-family: helvetica-medium;
  font-size: 5.5rem;
}
.helveticaHead2 {
  font-family: helvetica-medium;
}

@font-face {
  font-family: monograf-bold;
  src: url(../public/font/Monograf/Monograf-Bold.ttf);
}
.monografBody {
  font-family: monograf-bold;
  color: #787ff6;
}

/* floating UI https://floating-ui.com/docs/tutorial */
/* #tooltip {
  display: none;
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  background: #222;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
  font-size: 90%;
} */
