/* .container-container-cropper {
  height: 90%;
  width: 100vh;
} */

.container-cropper {
  height: 90%;
  padding: 7rem;
  /* display: flex; */
}

.cropper {
  height: 90%;
  /* position: relative;
  width: 100%;
 */
  position: absolute;
  width: 100%;
  top: 1rem;
  left: 0;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 3rem;
  padding-top: 4rem;
  width: 70%;
}

/* 
.container-buttons {
  border: 1px solid #f5f5f5;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
 */
