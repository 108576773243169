body, html {
    height: 100%;
    }

.bg {
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    }

.centerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    }
    